/**
   * showdoc
   * @catalog API/工具/Date
   * @title 日期基础类
   * @className ClientDate
   * @modifier static
   * @method DateFunc
   * @demo 
   */
import hiSetting from '@/hiSetting.js'
const DATE = {
	timeSchemeKey: {
		"today": "today",
		"yesterday": "yesterday",
		"week": "week",
		"month": "month",
		"premonth": "premonth",
		"quarter": "quarter",
		"year": "year",
		"days7": "days7",
		"days28": "days28",
		"days84": "days84",
		"halfyear": "halfyear",
		"oneyear": "oneyear"
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 日期格式化
	   * @description 将条件转成字符结果
	   * @method format
	   * @param date 必选 Date|String 需要格式化日期
	   * @param format 必选 String 格式化类型
	   * @return Date
	   * @number 60  
	   */
	format: function (date, format) {
		if (!date)
			return "";
		if (typeof date == "string")
			date = DATE.strToDate(date);
		format = format.replace(/HH/, "hh");
		var o = {
			"M+": date.getMonth() + 1, // month
			"d+": date.getDate(), // day
			"D+": date.getDate(), // day
			"h+": date.getHours(), // hour
			"H+": date.getHours(), // hour
			"m+": date.getMinutes(), // minute
			"s+": date.getSeconds(), // second
			"q+": Math.floor((date.getMonth() + 3) / 3), // quarter
			"S": date.getMilliseconds()
			// millisecond
		}
		if (/(y+)/.test(format))
			format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4
				- RegExp.$1.length));
		if (/(Y+)/.test(format))
			format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4
				- RegExp.$1.length));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(format))
				format = format.replace(RegExp.$1, RegExp.$1.length == 1
					? o[k]
					: ("00" + o[k]).substr(("" + o[k]).length));
		return format;
	},

	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获取服务端时间
	   * @description 服务端当前时间
	   * @method getServerTimeNow
	   * @return Date
	   * @number 60  
	   */
	getServerTimeNow() {
		let serverTimeNow = "";
		window.eap.ajax({
			url: window.HIVUI_SETTING.getNowTimeUrl,
			method: "GET",//可不传，默认post
			async: false,//可不传，默认true		
			success: function (response) {
				serverTimeNow = response.dataPack;
			},
			fail: function () {

			}
		});
		return serverTimeNow;
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 毫秒差间隔
	   * @description dt1与dt2日期之间毫秒差间隔
	   * @method milliSecondsBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	milliSecondsBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		return Math.abs(dt1.getTime() - dt2.getTime());
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 秒差间隔
	   * @description dt1与dt2日期之间秒差间隔
	   * @method secondsBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	secondsBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		if (!dt1 || !dt2)
			return null;
		return Math.abs((dt1.getTime() - dt2.getTime()) / 1000);
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 分钟差间隔
	   * @description dt1与dt2日期之间分钟差间隔
	   * @method minutesBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	// 分差
	minutesBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		if (!dt1 || !dt2)
			return null;
		return Math.floor(Math.abs((dt1.getTime() - dt2.getTime())
			/ (1000 * 60)));
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 时差间隔
	   * @description dt1与dt2日期之间时差间隔
	   * @method hoursBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	// 小时差
	hoursBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		if (!dt1 || !dt2)
			return null;
		return Math.floor(Math.abs((dt1.getTime() - dt2.getTime())
			/ (1000 * 3600)));
	},
	
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 天差间隔
	   * @description dt1与dt2日期之间天差间隔
	   * @method daysBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @param isAbs 选填 是否 返回绝对值
	   * @return Int
	   * @number 60  
	   */
	daysBetween: function (dt1, dt2, isAbs) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);

		if (!dt1 || !dt2)
			return null;
		dt1 = this.strFormatDate(this.format(dt1, "yyyy-MM-dd"));
		dt2 = this.strFormatDate(this.format(dt2, "yyyy-MM-dd"));
		if (isAbs)
			return Math.floor(Math.abs((dt1.getTime() - dt2.getTime())
				/ (1000 * 3600 * 24)));
		else
			return Math.floor((dt1.getTime() - dt2.getTime())
				/ (1000 * 3600 * 24));
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 月差间隔
	   * @description dt1与dt2日期之间月差间隔
	   * @method daysBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	monthsBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		if (!dt1 || !dt2)
			return null;
		return Math.abs((dt1.getFullYear() - dt2.getFullYear()) * 12)
			+ Math.abs(dt1.getMonth() - dt2.getMonth());
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 年差间隔
	   * @description dt1与dt2日期之间年差间隔
	   * @method yearsBetween
	   * @param dt1 必选 Date|String 日期时间1
	   * @param dt2 必选 Date|String 日期时间2
	   * @return Int
	   * @number 60  
	   */
	yearsBetween: function (dt1, dt2) {
		if (typeof dt1 == "string")
			dt1 = DATE.strToDate(dt1);
		if (typeof dt2 == "string")
			dt2 = DATE.strToDate(dt2);
		if (!dt1 || !dt2)
			return null;
		return Math.abs(dt1.getFullYear() - dt2.getFullYear());
	},

	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获取中文星期
	   * @description 获取中文星期
	   * @method getWeekCn
	   * @param dt 必选 Date|String 日期时间
	   * @return String
	   * @number 60  
	   */
	getWeekCn: function (dt) {
		var dayNames = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五",
			"星期六");
		if (typeof dt == "string")
			dt = DATE.strToDate(dt);
		return dayNames[dt.getDay()];
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获取数字星期
	   * @description 获取数字星期
	   * @url getWeek(dt)
	   * @method getWeek(dt)
	   * @param dt 必选 Date|String 日期时间
	   * @return String
	   * @number 60  
	   */
	getWeek: function (dt) {
		if (typeof dt == "string")
			dt = DATE.strToDate(dt);
		return dt.getDay();
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 是否时间日期
	   * @description 是否时间日期
	   * @method isDateTime
	   * @param dateString 必选 Date|String 日期时间
	   * @return boolean
	   * @number 60  
	   */
	isDateTime: function (dateString) {
		if (dateString.trim() == "")
			return false;
		// 年月日时分秒正则表达式
		var r = dateString
			.match(/^(\d{1,4})\-(\d{1,2})\-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/);
		var r1 = dateString
			.match(/^(\d{1,4})\-(\d{1,2})\-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2}).(\d{1,6})$/);
		var r2 = dateString
			.match(/^(\d{1,4})\-(\d{1,2})\-(\d{1,2}) (\d{1,2}):(\d{1,2})$/);
		if (r == null && r1 == null && r2 == null) {
			return false;
		}
		r = r || r1 || r2;
		if (r.length == 6) {// 时间格式为：2018-04-11 17:31
			var d1 = new Date(r[1], r[2] - 1, r[3], r[4], r[5]);
			let num = (d1.getFullYear() == r[1] && (d1.getMonth() + 1) == r[2]
				&& d1.getDate() == r[3] && d1.getHours() == r[4] && d1
					.getMinutes() == r[5]);
			if (num == 0) {
				return false;
			} else
				return true;
		}

		var d = new Date(r[1], r[2] - 1, r[3], r[4], r[5], r[6]);
		let num = (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[2]
			&& d.getDate() == r[3] && d.getHours() == r[4]
			&& d.getMinutes() == r[5] && d.getSeconds() == r[6]);
		if (num == 0) {
			return false;
		}
		return (num != 0);
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 是否日期类型
	   * @description 是否日期类型
	   * @method isDate
	   * @param dateString 必选 Date|String 日期时间
	   * @return boolean
	   * @number 60  
	   */
	isDate: function (dateString) {
		if (dateString == "")
			return true;
		// 年月日正则表达式
		var r = dateString.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
		if (r == null) {
			return false;
		}
		var d = new Date(r[1], r[3] - 1, r[4]);
		let num = (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d
			.getDate() == r[4]);
		if (num == 0) {
			return false;
		}
		return (num != 0);
	},
	dateof: function (dt, type) {
		let result;
		if (!dt)
			return "";
		if (typeof dt == "string")
			dt = DATE.strToDate(dt);
		switch (type) {
			case "y":
				result = dt.getFullYear();
				break;
			case "m":
				result = dt.getMonth() + 1;
				break;
			case "min":
				result = dt.getMinutes();
				break;
			case "time":
				result = DATE.format("yyyy-MM-dd hh:mm:ss");
				break;
			case "date":
				result = DATE.format('yyyy-MM-dd');
				break;
			case "weekCn":
				result = dt.week();
				break;
			case "week":
				result = dt.getDay();
				break;
			case "weekInYear":
				var first = new Date(dt.getFullYear(), 0, 1);
				var n = parseInt("1065432".charAt(first.getDay()));
				n = dt.getTime() - first.getTime() - n * 24 * 60 * 60 * 1000;
				n = Math.ceil(n / (7 * 24 * 60 * 60 * 1000));
				result = first.getDay() != 1 ? (n + 1) : n;
				break;
			default:
				result = "";
		}
		return result;
	},
	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 字符转换指定日期类型
   * @description 字符转换指定日期类型
   * @method strFormatDate
   * @param strDate 必选 String 日期时间
   * @param strFormat 必选 String 格式化类型
   * @return Date
   * @number 60  
   */
	strFormatDate: function (strDate, strFormat) {
		if (strDate == "" || strDate == null)
			return "";
		let d = DATE.strToDate(strDate);
		if (!strFormat)
			return d;
		return d.format(strFormat);
	},
	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 字符转换日期类型
   * @description 字符转换日期类型
   * @method strToDate
   * @param dataStr 必选 String 日期时间
   * @return Date
   * @number 60  
   */
	strToDate: function (dataStr) {
		if (!dataStr)
			return "";

		if (dataStr && dataStr.constructor === Date) { // 2010.08.02
			// 已经是时间类型，就不过滤。
			return dataStr;
		}
		if (typeof dataStr == "string") {
			if (dataStr.indexOf('.') > -1) // 2010.07.13 08335 cai
				dataStr = dataStr.split('.')[0];
			dataStr = dataStr.replace(/-/g, "/");
		}
		// 只有时间字符串 01521
		if ((dataStr.indexOf("-") == -1 && dataStr.indexOf("/") == -1) && dataStr.indexOf(".") == -1
			&& dataStr.indexOf(":") != -1)
			dataStr = DATE.dateOf(new Date()) + " " + dataStr;

		var arr = dataStr.split(/[- :]/);
		var arr1 = dataStr.split(/[/ :]/)
		if (dataStr != "" && (arr.length == 1 && arr1.length == 1)) { // 当：dataStr：1,返回：2010-01-01
			// 00:00:00
			console.log(dataStr + " Invalid Date")
			return "";
		}
		return new Date(Date.parse(dataStr));
	}
	,
	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 获得季度的第一天
   * @description 获得季度的第一天
   * @method getQuarterStartDay
   * @param dataStr 必选 Date 日期时间
   * @return Date
   * @number 60  
   */
	getQuarterStartDate: function () {
		var now = new Date();
		var QuarterStartDate = '';
		if (now.getMonth() < 3) {
			QuarterStartDate = new Date(now.getFullYear(), 0, 1);
			return this.format(QuarterStartDate, "yyyy-MM-dd");
		} else if (now.getMonth() > 2 && now.getMonth() < 6) {
			QuarterStartDate = new Date(now.getFullYear(), 3, 1);
			return this.format(QuarterStartDate, "yyyy-MM-dd");
		} else if (now.getMonth() > 5 && now.getMonth() < 9) {
			QuarterStartDate = new Date(now.getFullYear(), 6, 1);
			return this.format(QuarterStartDate, "yyyy-MM-dd");
		} else if (now.getMonth() > 8) {
			QuarterStartDate = new Date(now.getFullYear(), 9, 1);
			return this.format(QuarterStartDate, "yyyy-MM-dd");

		}
	},
	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 获得本季度的结束日期
   * @description 获得本季度的结束日期
   * @method getQuarterEndDate
   * @return Date
   * @number 60  
   */
	getQuarterEndDate: function () {
		var now = new Date();
		var QuarterStartDate = '';
		if (now.getMonth() < 3) {
			QuarterStartDate = new Date(now.getFullYear(), 2, 31);
			return this.format(QuarterStartDate, "yyyy-MM-dd");
		} else if (now.getMonth() > 2 && now.getMonth() < 6) {
			QuarterStartDate = new Date(now.getFullYear(), 6, 30);
			return this.format(QuarterStartDate, "yyyy-MM-dd");
		} else if (now.getMonth() > 5 && now.getMonth() < 9) {
			QuarterStartDate = new Date(now.getFullYear(), 8, 30);
			return this.format(QuarterStartDate, "yyyy-MM-dd");


		} else if (now.getMonth() > 8) {
			QuarterStartDate = new Date(now.getFullYear(), 11, 31);
			return this.format(QuarterStartDate, "yyyy-MM-dd");

		}
	},

	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 获得本年度的开始日期
   * @description 获得本年度的开始日期
   * @method getYearStartDate
   * @return Date
   * @number 60  
   */
	getYearStartDate: function () {
		var now = new Date();
		var yearFirstDay = new Date(now.getFullYear(), 0, 1);
		return this.format(yearFirstDay, "yyyy-MM-dd");
		//return yearFirstDay.format("yyyy-MM-dd");
	},

	/**
   * showdoc
  * @catalog API/工具/Date
   * @title 获得本年度的结束日期
   * @description 获得本年度的结束日期
   * @method getYearEndDate
   * @return Date
   * @number 60  
   */
	getYearEndDate: function () {
		var now = new Date();
		var yearLastDay = new Date(now.getFullYear(), 11, 31);
		return this.format(yearLastDay, "yyyy-MM-dd");
		//return yearLastDay.format("yyyy-MM-dd");
	},
	/**
   * showdoc
   * @catalog API/工具/Date
   * @title 获得某月的天数
   * @description 获得某月的天数
   * @method getMonthDays
   * @param myMonth，myYear 必选 int 月份
   * @param myMonth，myYear 必选 int 年份
   * @return Int
   * @number 60  
   */
	getMonthDays: function (myMonth, myYear) {
		if (myYear == undefined) {
			var now = new Date();
			myYear = now.getFullYear();
		}
		myMonth--;
		var d = new Date(myYear, myMonth, 1);
		d.setDate(d.getDate() + 32 - d.getDate());
		return (32 - d.getDate())
	},

	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获得本周的开始日期
	   * @description 获得本周的开始日期
	   * @method getWeekStartDate
	   * @return Date
	   * @number 60  
	   */
	getWeekStartDate: function () {
		var now = new Date();
		var weekStartDate = new Date(now.getFullYear(), now.getMonth(), now
			.getDate()
			- now.getDay());
		return this.format(weekStartDate, "yyyy-MM-dd");
	},

	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获得本周的结束日期
	   * @description 获得本周的结束日期
	   * @method getWeekEndDate
	   * @return Date
	   * @number 60  
	   */
	getWeekEndDate: function () {
		var now = new Date();
		var weekEndDate = new Date(now.getFullYear(), now.getMonth(), now
			.getDate()
			+ (6 - now.getDay()));
		return this.format(weekEndDate, "yyyy-MM-dd");
		//return weekEndDate.format("yyyy-MM-dd");
	},

	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获得本月的开始日期
	   * @description 获得本月的开始日期
	   * @method getMonthStartDate
	   * @param dateVal 必选 Date|String 日期
	   * @return Date
	   * @number 60  
	   */
	getMonthStartDate: function (dateVal) {
		var now = new Date();
		if (dateVal && typeof dateVal == "string")
			now = DATE.strToDate(dateVal);
		var monthStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
		return this.format(monthStartDate, "yyyy-MM-dd");
		//monthStartDate.format("yyyy-MM-dd");
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获得本月的结束日期
	   * @description 获得本月的结束日期
	   * @method getMonthEndDate
	   * @return Date
	   * @number 60  
	   */
	getMonthEndDate: function (dateVal) {
		var now = new Date();

		if (dateVal && typeof dateVal == "string")
			now = DATE.strToDate(dateVal);

		let year = now.getFullYear();
		let month = now.getMonth();
		var tempDate = new Date(new Date(year, month + 1, 1).getTime() - 1000
			* 60 * 60 * 24)
		return this.format(tempDate, "yyyy-MM-dd");
		//return tempDate.format("yyyy-MM-dd");
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获当前时间
	   * @description 获当前时间
	   * @method now
	   * @param format 可选 String 格式化
	   * @return Date
	   * @number 60  
	   */
	now: function () {
		let format = "yyyy-MM-dd hh:mm:ss";
		return this.format(new Date(), format);
	},
	currentNow: function () {
		return new Date();
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 获时间方案
	   * @description 获时间方案
	   * @method TimeScheme
	   * @param name 必选 String 方案名称
	   * @return Date
	   * @number 60  
	   */
	TimeScheme: function (name,split) {
		split = split ||"到";
		var startTime = ` 00:00:00${split}`, endTime = " 23:59:59";
		switch (name) {
			case "today":
				return this.format(this.strToDate(this.now()), "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

			case "yesterday":
				return this
					.format(this.dateAdd("d", -1, this.now()),
						"yyyy-MM-dd")
					+ startTime
					+ this.format(this.dateAdd("d", -1, this
						.now()), "yyyy-MM-dd")
					+ endTime;

			case "week":
				return this.getWeekStartDate() + startTime
					+ this.getWeekEndDate() + endTime;

			case "month":
				return this.getMonthStartDate() + startTime
					+ this.getMonthEndDate() + endTime;

			case "quarter":
				return this.getQuarterStartDate() + startTime
					+ this.getQuarterEndDate() + endTime;

			case "year":
				return this.getYearStartDate() + startTime
					+ this.getYearEndDate() + endTime;

			case "days7":
				return this.format(this.dateAdd("d", -7, this.now())
					, "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

			case "days28":
				return this.format(this.dateAdd("d", -28, this.now())
					, "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

			case "days84":
				return this.format(this.dateAdd("d", -84, this.now())
					, "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

			case "halfyear":
				return this.format(this.dateAdd("d", -180, this.now())
					, "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

			case "oneyear":
				return this.format(this.dateAdd("d", -365, this.now())
					, "yyyy-MM-dd")
					+ startTime
					+ this.format(this.strToDate(this.now()),
						"yyyy-MM-dd") + endTime;

		}
	},
	daysInMonth: function (year, month) {
		if (month == 1) {
			if (year % 4 == 0 && year % 100 != 0)
				return 29;
			else
				return 28;
		} else if ((month <= 6 && month % 2 == 0) || (month = 6 && month % 2 == 1))
			return 31;
		else
			return 30;
	},
	/**
	   * showdoc
	  * @catalog API/工具/Date
	   * @title 加减日期
	   * @description 在日期中添加或者减去指定的时间间隔天数
	   * @method dateAdd
	   * @param datepart 必选 String 间隔类型:y:年,q:季度,m:月,d:天,h:小时,mi:分,s:秒
	   * @param number 必选 Int 间隔数,正数表示加,负数表示减
	   * @param dtDate 必选 Date 
	   * @return Date
	   * @number 60  
	   */
	dateAdd: function (datepart, number, dtDate) {
		if (typeof dtDate == "string")
			dtDate = DATE.strToDate(dtDate);
		var date = new Date(dtDate);
		datepart = (datepart || 'd').toLowerCase();
		let diff = parseInt(number);
		let tempdate;
		switch (datepart) {
			case "y": // 年
				tempdate = date.setYear(date.getFullYear() + diff)
				break;
			case "q": // 季度
				tempdate = date.setMonth(date.getMonth() + (diff * 3))
				break;
			case "m": // 月

				var sYear = date.getFullYear();
				var sMonth = date.getMonth();
				var nextY = sYear;
				var nextM = sMonth;
				//如果当前月+要加上的月>11 这里之所以用11是因为 js的月份从0开始
				if ((sMonth + diff) > 11) {
					nextY = sYear + 1;
					nextM = parseInt(sMonth + diff) - 12;
				} else {
					nextM = date.getMonth() + diff
				}
				var daysInNextMonth = this.daysInMonth(nextY, nextM);
				var day = date.getDate();
				if (day > daysInNextMonth) {
					day = daysInNextMonth;
				}
				//eDate = new Date(nextY, nextM, day);
				tempdate = new Date(nextY, nextM, day);

				break;
			case "d": // 天
				tempdate = date.setDate(date.getDate() + diff);
				break;
			case "h": // 时
				tempdate = date.setHours(date.getHours() + diff);
				break;
			case "mi": // 分
				tempdate = date.setMinutes(date.getMinutes() + diff);
				break;
			case "s": // 秒
				tempdate = date.setSeconds(date.getSeconds() + diff)
				break;
			default:
				tempdate = date.setDate(date.getDate() + diff);
				break;
		}
		return new Date(tempdate);
	}
}
export default DATE;




